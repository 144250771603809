import BaseStyle from 'primevue/base/style';

var css = "\n@layer primevue {\n    .p-divider-horizontal {\n        display: flex;\n        width: 100%;\n        position: relative;\n        align-items: center;\n    }\n\n    .p-divider-horizontal:before {\n        position: absolute;\n        display: block;\n        top: 50%;\n        left: 0;\n        width: 100%;\n        content: '';\n    }\n\n    .p-divider-content {\n        z-index: 1;\n    }\n\n    .p-divider-vertical {\n        min-height: 100%;\n        margin: 0 1rem;\n        display: flex;\n        position: relative;\n        justify-content: center;\n    }\n\n    .p-divider-vertical:before {\n        position: absolute;\n        display: block;\n        top: 0;\n        left: 50%;\n        height: 100%;\n        content: '';\n    }\n\n    .p-divider-solid.p-divider-horizontal:before {\n        border-top-style: solid;\n    }\n\n    .p-divider-solid.p-divider-vertical:before {\n        border-left-style: solid;\n    }\n\n    .p-divider-dashed.p-divider-horizontal:before {\n        border-top-style: dashed;\n    }\n\n    .p-divider-dashed.p-divider-vertical:before {\n        border-left-style: dashed;\n    }\n\n    .p-divider-dotted.p-divider-horizontal:before {\n        border-top-style: dotted;\n    }\n\n    .p-divider-dotted.p-divider-vertical:before {\n        border-left-style: dotted;\n    }\n}\n";

/* Position */
var inlineStyles = {
  root: function root(_ref) {
    var props = _ref.props;
    return {
      justifyContent: props.layout === 'horizontal' ? props.align === 'center' || props.align === null ? 'center' : props.align === 'left' ? 'flex-start' : props.align === 'right' ? 'flex-end' : null : null,
      alignItems: props.layout === 'vertical' ? props.align === 'center' || props.align === null ? 'center' : props.align === 'top' ? 'flex-start' : props.align === 'bottom' ? 'flex-end' : null : null
    };
  }
};
var classes = {
  root: function root(_ref2) {
    var props = _ref2.props;
    return ['p-divider p-component', 'p-divider-' + props.layout, 'p-divider-' + props.type, {
      'p-divider-left': props.layout === 'horizontal' && (!props.align || props.align === 'left')
    }, {
      'p-divider-center': props.layout === 'horizontal' && props.align === 'center'
    }, {
      'p-divider-right': props.layout === 'horizontal' && props.align === 'right'
    }, {
      'p-divider-top': props.layout === 'vertical' && props.align === 'top'
    }, {
      'p-divider-center': props.layout === 'vertical' && (!props.align || props.align === 'center')
    }, {
      'p-divider-bottom': props.layout === 'vertical' && props.align === 'bottom'
    }];
  },
  content: 'p-divider-content'
};
var DividerStyle = BaseStyle.extend({
  name: 'divider',
  css: css,
  classes: classes,
  inlineStyles: inlineStyles
});

export { DividerStyle as default };
